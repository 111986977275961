<template>
  <layout class="main-page">
    <!-- Start Banner Area -->

    <div class="slider-one rn-section-gapTop" style="padding-top: 0px">
      <div class="container">
        <div class="row row-reverce-sm align-items-center">
          <div class="col-lg-5 col-md-6 col-sm-12 mt_sm--50">
            <h2
              class="title"
              style="margin-bottom: 20px"
              data-sal-delay="200"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              Серверы для бизнеса
            </h2>
            <p
              class="slide-disc"
              style="margin-bottom: 40px"
              data-sal-delay="300"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              Наши серверы обеспечивают высокую производительность и стабильную
              работу для самых требовательных проектов.
            </p>
            <div class="button-group">
              <router-link
                class="btn btn-large btn-primary"
                to="/activate"
                data-sal-delay="400"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                Оформить заявку
              </router-link>
              <!-- <router-link class="btn btn-large btn-primary-alta" to="/create" data-sal-delay="500" data-sal="slide-up" data-sal-duration="800">
                                Create
                            </router-link> -->
            </div>
          </div>
          <div class="col-lg-5 col-md-6 col-sm-12 offset-lg-1">
            <div class="slider-thumbnail">
              <!-- <img style="border-radius: 24px;" :src="require(`@/assets/images/slider/main-2.png`)"
                                alt="Slider Images"> -->
              <video-style-one
                video-url="https://www.youtube.com/embed/9zDrgU6xJd4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <explore-live-carousel/> -->

    <service-style-one id="advantages" />

    <div class="container" id="company">
      <div class="row g-5">
        <div class="col-12 mb--50 mt--60">
          <h3
            class="title sal-animate"
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            О компании
          </h3>
        </div>
      </div>
      <div>
        <h4 class="title" style="font-weight: normal">Технологии highNet</h4>

        Удобная аренда вычислительных ресурсов, а также безопасное хранение
        данных — далеко не все, что мы можем предложить.

        <br />
        <br />
        <br />
        <h4 class="title" style="font-weight: normal">
          Предложения и возможности highNet
        </h4>
        HighNet — это технологическая компания, а ее миссия — движение к лучшему
        миру. Мы разрабатываем и поддерживаем продукты, позволяющие нам
        агрегировать вычислительные ресурсы пользователей для дальнейшей их
        реаллизации с целью ускорения развития прогресса.
        <br />
        <br />
        Наша технология обеспечивает возможность моментальной аренды
        вычислительных ресурсов и облачных систем хранения по всему миру с
        любого устройства.
        <br />
        <br />

        HighNet заинтересует специалистов в области информационных технологий,
        вычислительных наук, и распределенных систем, которые заинтересованы в
        методах обработки данных и вычислений.
        <br />
        <br />

        Также HighNet направлен на широкую общественность, в особенности на
        развитие общественно полезных технологий и увеличивает их потенциальное
        влияние на нашу жизнь и будущее.

        <div class="row g-5 mt--10">
          <div class="col-lg-12">
            <div class="row g-5">
              <!-- Start single wallet -->

              <div class="col-lg-6 col-md-4 col-12 col-sm-6 col-12">
                <div class="wallet-wrapper">
                  <div class="inner">
                    <div class="icon"></div>
                    <div class="content">
                      <h4 class="title">
                        <a href="#">Поддержка корпоративных клиентов</a>
                      </h4>
                      <p
                        class="description"
                        style="display: flex; flex-direction: column; gap: 8px"
                      >
                        <span> Системное администрирование </span>
                        <span> Переезд в HighNet </span>
                        <span> Хранение данных </span>
                        <span> Дата-центр под ключ </span>
                        <span> Серверы под заказ </span>
                        <span> Отказоустойчивая IT инфраструктура </span>
                      </p>
                    </div>
                  </div>
                  <a
                    aria-current="page"
                    href="/stack#"
                    class="router-link-active router-link-exact-active over-link"
                  ></a>
                </div>
              </div>

              <!-- End single wallet --><!-- Start single wallet -->
              <div class="col-lg-6 col-md-4 col-12 col-sm-6 col-12">
                <div class="wallet-wrapper">
                  <div class="inner">
                    <div class="icon"></div>
                    <div class="content">
                      <h4 class="title">
                        <a href="#">Распределённые вычислительные сети</a>
                      </h4>
                      <p
                        class="description"
                        style="display: flex; flex-direction: column; gap: 8px"
                      >
                        Продажа вычислительных мощностей для компаний,
                        организаций и учреждений, а точнее:
                        <span>Исследователи и инженеры </span>

                        <span>Компании и предприниматели</span>

                        <span>Учебные и научные учреждения</span>
                        <span>Стартапы и инновационные компании</span>
                        <span
                          >Государственные организации и органы управления</span
                        >
                      </p>
                    </div>
                  </div>
                  <a
                    aria-current="page"
                    href="/stack#"
                    class="router-link-active router-link-exact-active over-link"
                  ></a>
                </div>
              </div>

              <div class="col-lg-6 col-md-4 col-12 col-sm-6 col-12">
                <div class="wallet-wrapper">
                  <div class="inner">
                    <div class="icon"></div>
                    <div class="content">
                      <h4 class="title"><a href="#">Облачные вычисления</a></h4>
                      <p
                        class="description"
                        style="display: flex; flex-direction: column; gap: 8px"
                      >
                        <span>
                          Аренда мощностей для выполнения ресурсоёмких задач.
                        </span>

                        <span>
                          Поддержка распределённых вычислений для
                          масштабируемости и гибкости.
                        </span>

                        <span>
                          Быстрая обработка данных для аналитики, ИИ и машинного
                          обучения.
                        </span>
                      </p>
                    </div>
                  </div>
                  <a
                    aria-current="page"
                    href="/stack#"
                    class="router-link-active router-link-exact-active over-link"
                  ></a>
                </div>
              </div>

              <div class="col-lg-6 col-md-4 col-12 col-sm-6 col-12">
                <div class="wallet-wrapper">
                  <div class="inner">
                    <div class="icon"></div>
                    <div class="content">
                      <h4 class="title">
                        <a href="#">Наши преимущества</a>
                      </h4>
                      <p
                        class="description"
                        style="display: flex; flex-direction: column; gap: 8px"
                      >
                        <span>Высокая производительность и стабильность</span>
                        <span>Гибкость масштабирования</span>
                        <span
                          >Доступность и безопасность на уровне мировых
                          стандартов</span
                        >
                      </p>
                    </div>
                  </div>
                  <a
                    aria-current="page"
                    href="/stack#"
                    class="router-link-active router-link-exact-active over-link"
                  ></a>
                </div>
              </div>
              <!-- End single wallet -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <explore-newest-item/> -->

    <!-- <seller-style-one/> -->

    <explore-filter id="rate" />

    <!-- <collection-style-one/> -->

    <share-modal />

    <report-modal />

    <Stack />

    <div class="rn-contact-top-area rn-section-gapTop bg_color--5" id="details">
      <div class="container">
        <div class="row g-5">
          <div
            class="col-lg-12"
            data-sal="slide-up"
            data-sal-delay="150"
            data-sal-duration="800"
          >
            <div class="section-title mb--30 text-left">
              <h2 class="title">Реквизиты</h2>
              <!-- <p class="description"> -->
              <!-- </p> -->
            </div>
          </div>

          <div class="contact-item">
            Полное наименование:
            <span> ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ХАЙНЕТ" </span>
          </div>

          <div class="contact-item">
            ИНН:
            <span> 4705119549 </span>
          </div>

          <div class="contact-item">
            Расчетный счет:
            <span> 40702810610001653965 </span>
          </div>

          <div class="contact-item">
            Банк:
            <span> АО "ТБанк" </span>
          </div>

          <div class="contact-item">
            Юридический адрес банка:
            <span> Москва, 127287, ул. 2-я Хуторская, д. 38А, стр. 26 </span>
          </div>

          <div class="contact-item">
            Корр. счет банка:
            <span> 30101810145250000974 </span>
          </div>

          <div class="contact-item">
            ИНН банка:
            <span> 7710140679 </span>
          </div>

          <div class="contact-item">
            БИК банка:
            <span> 044525974 </span>
          </div>

          <div class="contact-item">
            График работы:
            <span> с ПН-ПТ с 09:00 - 17:00 </span>
          </div>
        </div>

        <div
          class="rn-contact-top-area rn-section-gapTop bg_color--5"
          id="contacts"
        >
          <div class="row g-5" id="contacts">
            <div
              class="col-lg-12"
              data-sal="slide-up"
              data-sal-delay="150"
              data-sal-duration="800"
            >
              <div class="section-title mb--30 text-left">
                <h2 class="title">Для связи с нами</h2>
                <!-- <p class="description"> -->
                <!-- </p> -->
              </div>
            </div>

            <div class="contact-item">
              Телефон: <a href="tel:+79939460666">+7 993 946-06-66</a>
            </div>

            <div class="contact-item">
              Почта:
              <a href="mailto:79939460666@mail.ru">79939460666@mail.ru</a>
            </div>

            <div class="contact-item">
              Адрес:
              <span>
                188370, Ленинградская Область, Гатчинский, П Елизаветино, Пл
                Дружбы, д. 38, Офис 302
              </span>
            </div>
          </div>
        </div>

        <!-- <div class="rn-contact-top-area rn-section-gapTop bg_color--5" id="contacts"> -->

        <!-- <div class="row g-5" id="adress">
                        <div class="col-lg-12" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
                            <div class="section-title mb--70 text-left">
                                <h2 class="title">Адрес</h2> -->
        <!-- <p class="description"> -->
        <!-- </p> -->
        <!-- </div>
                        </div> -->

        <!-- <div style="position:relative;overflow:hidden; max-width: 1420px; margin: 0 auto;">
                            <a href="https://yandex.ru/maps/10174/saint-petersburg-and-leningrad-oblast/?utm_medium=mapframe&utm_source=maps"
                                style="color:#eee;font-size:12px;position:absolute;top:0px;">Санкт‑Петербург и
                                Ленинградская
                                область</a><a
                                href="https://yandex.ru/maps/10174/saint-petersburg-and-leningrad-oblast/house/ploshchad_druzhby_38/ZkAYcQBpTkQPQFtjfXlxeXhnZw==/?ll=29.779717%2C59.508443&utm_medium=mapframe&utm_source=maps&z=16.59"
                                style="color:#eee;font-size:12px;position:absolute;top:14px;">Площадь Дружбы, 38 —
                                Яндекс Карты</a>
                            <iframe
                                src="https://yandex.ru/map-widget/v1/?ll=29.779717%2C59.508443&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoyMjEwNjA1NzU0ErsB0KDQvtGB0YHQuNGPLCDQm9C10L3QuNC90LPRgNCw0LTRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwg0JPQsNGC0YfQuNC90YHQutC40Lkg0LzRg9C90LjRhtC40L_QsNC70YzQvdGL0Lkg0L7QutGA0YPQsywg0L_QvtGB0ZHQu9C-0Log0JXQu9C40LfQsNCy0LXRgtC40L3Qviwg0L_Qu9C-0YnQsNC00Ywg0JTRgNGD0LbQsdGLLCAzOCIKDdw87kEVpQhuQg%2C%2C&z=16.59"
                                width="560" height="550" frameborder="1" allowfullscreen="true"
                                style="position:relative;">
                            </iframe>
                        </div> -->
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "../../components/layouts/Layout";
import ExploreLiveCarousel from "@/components/explore/ExploreLiveCarousel";
import ServiceCard from "@/components/service/ServiceCard";
import ProductCard from "@/components/product/ProductCard";
import MainProduct from "@/components/product/MainProduct";
import SalScrollAnimationMixin from "@/mixins/SalScrollAnimationMixin";
import ShareModal from "@/components/modal/ShareModal";
import ReportModal from "@/components/modal/ReportModal";
import ExploreFilter from "@/components/explore/ExploreFilter";
import ServiceStyleOne from "@/components/service/ServiceStyleOne";
import SellerStyleOne from "@/components/seller/SellerStyleOne";
import CollectionStyleOne from "@/components/collection/CollectionStyleOne";
import ExploreNewestItem from "@/components/explore/ExploreNewestItem";
import VideoStyleOne from "@/components/video/VideoStyleOne";
import Stack from "@/views/Stack";

export default {
  name: "HomePageOne",
  components: {
    ExploreNewestItem,
    CollectionStyleOne,
    SellerStyleOne,
    ServiceStyleOne,
    ExploreFilter,
    ReportModal,
    VideoStyleOne,
    Stack,
    MainProduct,
    ShareModal,
    ProductCard,
    ServiceCard,
    ExploreLiveCarousel,
    Layout,
  },
  mixins: [SalScrollAnimationMixin],
};
</script>



<style>
.description span::before {
  content: "— ";
}
.main-page {
  h3,
  h2 {
    margin: 0;
  }

  @media only screen and (max-width: 767px) {
    .section-title,
    .title {
      margin-top: 80px;
    }
  }
}

.contact-item {
  display: flex;
  gap: 12px;
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
  font-weight: var(--p-regular);
}

.active-dark-mode {
  .contact-item {
    span {
      color: var(--color-heading);
    }
  }
}

.active-light-mode {
  .contact-item {
    span,
    a {
      color: #000;
    }
  }
}
</style>